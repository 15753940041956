<template>
  <div>
    <!--    <sw-help-tooltip :description="'TESTERKA'" />-->
    <!-- Import Modal -->
    <import-contacts-modal
      v-if="checkRequiredPermissions([$roles.CONTACT_ADD_IMPORT])"
      :show="showImport"
      @close-modal="showImport = false"
      @reload-content="getContacts"
    />

    <!--    Section: Filters    -->
    <sw-filters
      :res="[
        {
          title: $t('All'),
          value: () => pagination ? pagination.totalRecords : 0,
        }
      ]"
      :shorts="[
        {
          title: $t('funnel.AssignedUsers'),
          type: 'avatar',
          value: () => filters.assignedUsers,
          clear: () => {
            filters.assignedUsers = []
            changeAssignedUsers([]);
            reloadContent()
          }
        },
        {
          title: $t('funnel.ShowStructureContacts'),
          variant: 'primary',
          value: () => filters.structureContacts ? $t('funnel.ShowStructureContacts') : null,
          clear: () => { filters.structureContacts = false; reloadContent() }
        },
        {
          title: $t('funnel.SelectContactStatus'),
          variant: 'primary',
          value: () => filters.status ? contactStatuses.find(({ id }) => id === filters.status).name : null,
          clear: () => { filters.status = ''; reloadContent() }
        },
        {
          title: $t('ChooseTags'),
          value: () => filters.tags.map(({ name }) => name).join(', '),
          clear: () => { filters.tags = []; reloadContent() }
        },
        {
          variant: 'primary',
          value: () => filters.action ? $t('funnel.ContactsWithoutAction') : null,
          clear: () => { filters.action = false; reloadContent() }
        },
        {
          title: $t('DateReceipt'),
          value: () => filters.timeRange,
          clear: () => { filters.timeRange = ''; reloadContent() }
        },
        {
          variant: 'primary',
          value: () => filters.withOrganization ? $t('ShowContactsAssignedToOrganization') : null,
          clear: () => { filters.withOrganization = false; reloadContent() }
        },
        {
          variant: 'primary',
          value: () => filters.agreeMarketing ? $t('contact.agreeMarketing') : null,
          clear: () => { filters.agreeMarketing = false; reloadContent() }
        },
      ]"
      mt
    >
      <b-row class="client_filters">
        <b-col
          cols="12"
          lg="8"
          class="mb-1"
        >
          <!--  Table Filters - Users -->
          <sw-select :name="$t('funnel.AssignedUsers')">
            <assigned-users
              :value="filters.assignedUsers"
              :is-multiple="true"
              @input="changeAssignedUsers"
            />
          </sw-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <!--  Table Filters - Users - status -->
          <sw-select :name="$t('funnel.SelectContactStatus')">
            <v-select
              v-model="filters.status"
              :options="contactStatuses"
              :reduce="status => status.id.toString()"
              label="name"
              @open="loadContactStatuses"
              @input="filterContacts"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name, color }">
                <div
                  class="color-dot"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
              <template #selected-option="{ name, color }">
                <div
                  class="color-dot"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
            </v-select>
          </sw-select>
          <!--  Table Filters - Users - status -->
        </b-col>

        <b-col
          v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_TAGS])"
          cols="12"
          md="4"
          class="mb-1"
        >
          <!--  Table Filters - Users - tags -->
          <sw-select :name="$t('ChooseTags')">
            <v-select
              v-model="filters.tags"
              multiple
              :close-on-select="false"
              :options="tags"
              label="name"
              @input="filterContacts"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
              <template #option="{ name, color }">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
              <template #selected-option="{ name, color }">
                <div
                  class="color-dot"
                  style="border: 1px solid white"
                  :style="{ backgroundColor: color }"
                />
                {{ name }}
              </template>
            </v-select>
          </sw-select>

          <b-form-checkbox
            v-model="filters.tagsSum"
            variant="primary"
            switch
            @change="filterContacts"
          >
            {{ filters.tagsSum ? $t('InterceptSum') : $t('InterceptSeparately') }}
            <feather-icon
              v-b-tooltip
              class="text-primary"
              icon="AlertCircleIcon"
              :title="!filters.tagsSum ? $t('ContactMustIncludeMinimumOne') : $t('ContactMustIncludeEach')"
            />
          </b-form-checkbox>
          <!--  Table Filters - Users - status -->
        </b-col>

        <b-col
          cols="12"
          lg="4"
          class="position-relative mb-1"
        >
          <sw-select :name="$t('funnel.DateRange')">
            <sw-select-fpr
              :is-empty="filters.timeRange"
              @clear="clearTimeRange()"
            >
              <flat-pickr
                ref="timeRange"
                v-model="filters.timeRange"
                class="form-control"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
                @on-close="filterContacts"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center mb-1"
        >
          <div>
            <b-form-checkbox
              v-model="filters.structureContacts"
              class="custom-control-primary mb-50 mr-1"
              variant="primary"
              @change="filterContacts"
            >{{ $t('funnel.ShowStructureContacts') }}
            </b-form-checkbox>
            <!--  Table Filters - Users - action -->
            <b-form-checkbox
              v-model="filters.action"
              class="mr-1 mb-50"
              variant="primary"
              @change="filterContacts"
            >{{ $t('funnel.ContactsWithoutAction') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filters.withOrganization"
              class="mr-1 mb-50"
              variant="primary"
              @change="() => { filters.onlyNoOrganizationContacts = false; filterContacts(); }"
            >
              {{ $t('ShowContactsAssignedToOrganization') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filters.onlyNoOrganizationContacts"
              class="mr-1 mb-50"
              variant="primary"
              @change="() => { filters.withOrganization = false; filterContacts(); }"
            >
              {{ $t('HideCompanyContacts') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="filters.agreeMarketing"
              class="mr-1"
              variant="primary"
              @change="() => { filterContacts(); }"
            >
              {{ $t('contact.agreeMarketing') }}
            </b-form-checkbox>
          </div>
        </b-col>

        <b-col sm="12">
          <div>
            <!-- toggle button -->
            <div class="d-flex">
              <a
                v-b-toggle.filters
                class="text-primary d-flex align-items-center"
                size="sm"
              >
                {{ $t('ShowAdditionalFieldsFilers') }}
                <b-badge
                  v-b-tooltip
                  class="ml-25"
                  variant="light-primary"
                  :title="$t('ActiveFilters')"
                >{{ getActiveFilters() }}</b-badge>
              </a>

              <div v-if="getActiveFilters()">
                <span class="px-50">|</span>
                <span
                  class="text-danger cursor-pointer"
                  @click="clearFilters"
                >
                  {{ $t('Clear') }}
                </span>
              </div>
            </div>

            <b-collapse
              id="filters"
            >
              <hr>
              <b-row>
                <b-col
                  v-for="(attribute, index) in additionalFieldsList.filter(r => r.type !== 'TOOLTIP')"
                  :key="`filter_${attribute.id}`"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group :label="attribute.name">
                    <sw-select
                      v-if="['TIME', 'DATE'].includes(attribute.type)"
                      class="mb-0"
                    >
                      <sw-select-fpr
                        @clear="attribute.value = ''"
                      >
                        <flat-pickr
                          :key="attribute.value"
                          v-model="attribute.value"
                          class="form-control form-control-sm"
                          :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale), ...timeConfig(attribute.type), defaultDate: attribute.value }"
                          @input="changeFilter"
                        />
                      </sw-select-fpr>
                    </sw-select>

                    <!-- Number Range -->
                    <div
                      v-else-if="attribute.type === 'DIGITAL'"
                      class="d-flex"
                    >
                      <b-input-group
                        :prepend="$t('From')"
                        size="sm"
                        class="mr-50"
                      >
                        <b-form-input
                          v-model="attribute.value[0]"
                          type="number"
                          size="sm"
                          @blur.enter="changeFilter"
                        />
                      </b-input-group>
                      <b-input-group
                        :prepend="$t('To')"
                        size="sm"
                      >
                        <b-form-input
                          v-model="attribute.value[1]"
                          type="number"
                          size="sm"
                          @blur.enter="changeFilter"
                        />
                      </b-input-group>
                    </div>
                    <b-form-radio-group v-else-if="['RADIAL'].includes(attribute.type)">
                      <b-form-radio
                        :key="`radio_0_0`"
                        v-model="attribute.value"
                        :value="''"
                        inline
                        @change="changeFilter"
                      >
                        {{ $t('Customizer.Animations.None') }}
                      </b-form-radio>
                      <b-form-radio
                        v-for="(radio,radindex) in attribute.contactAddonFieldTypeOptions"
                        :key="`radio_${index}_${radindex}`"
                        v-model="attribute.value"
                        :value="radio.name"
                        inline
                        @change="changeFilter"
                      >
                        {{ radio.name }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <sw-select
                      v-else-if="['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(attribute.type)"
                      class="mb-0"
                    >
                      <v-select
                        v-model="attribute.value"
                        :multiple="attribute.type === 'TEXT_MULTIPLE_ARRAY'"
                        :options="attribute.contactAddonFieldTypeOptions"
                        :get-option-label="attribute => attribute.id"
                        class="select-size-sm"
                        @input="changeFilter"
                      >
                        <template #option="item">
                          {{ item.name }}
                        </template>
                        <template #selected-option="item">
                          {{ item.name }}
                        </template>
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                    <b-form-input
                      v-else
                      v-model="attribute.value"
                      type="text"
                      size="sm"
                      @blur.enter="changeFilter"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
    </sw-filters>

    <div class="my-50">
      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_ADD])"
        variant="primary"
        class="mr-50"
        size="sm"
        to="/contacts/new"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>
      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_DELETE])"
        variant="primary"
        size="sm"
        class="mr-50"
        :disabled="!selectedContact.length"
        @click="deleteContacts(null)"
      >
        <feather-icon icon="TrashIcon" />
        {{ $t('Delete') }}

        <span v-if="selectedContact.length">({{ selectedContact.length }})</span>
      </b-button>
      <!--      <b-button-->
      <!--        v-if="checkRequiredPermissions([$roles.CONTACT_ADD])"-->
      <!--        variant="primary"-->
      <!--        style="margin-top: -15px"-->
      <!--        size="sm"-->
      <!--        @click="getProductsToExport"-->
      <!--      >-->
      <!--        <feather-icon icon="PlusIcon" />-->
      <!--        {{ $t('Add') }}-->
      <!--      </b-button>-->
      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_EDIT_DETAILS])"
        variant="primary"
        size="sm"
        class="mr-50"
        :disabled="massUpdateLoading"
        @click="showMassUpdate = true"
      >
        <feather-icon icon="DatabaseIcon" />
        {{ $t('UpdateContacts') }}

        <span v-if="selectedContact.length">({{ selectedContact.length }})</span>
      </b-button>
      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_ADD_IMPORT])"
        variant="primary"
        class="mr-50"
        size="sm"
        @click="showImport = true"
      >
        <feather-icon icon="UploadCloudIcon" />
        {{ $t('Import') }}
      </b-button>
      <ExportButton
        v-if="checkRequiredHostnameFragments([])"
        data-type="CONTACT"
        :filters-params="resolveParams()"
      />
    </div>

    <b-card>
      <!-- eslint-disable -->
      <contact-table
        ref="table"
        :props-user="propsUser"
        :filters="filters"
        :reloading="reload"
        :show-mass-update="showMassUpdate"
        :additional-fields-filters="additionalFieldsList"
        :per-page.async="pagination"
        @changeAssignedUsers="changeAssignedUsers"
        @changeMassUpdateState="massUpdateState"
        @setPagination="pagination = $event"
        @delete-item="deleteContacts"
        @setSorter="sorter = $event"
        @select-contacts="selectedContact = $event"
      />
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import * as roles from '@/helpers/permissions'
import ImportContactsModal from '@/views/modals/ImportContactsModal.vue'
import {
  BCollapse, BFormRadioGroup, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { loadFromLocalStorage, saveToLocalStorage } from '@/helpers/localStorage'
// import useContacts from '@/composable/modules/useContacts'
import XLSX from 'xlsx'
import axiosIns from '@/libs/axios'
import ExportButton from '@/views/migrations/ExportButton.vue'
import { resolveFilters } from '@/store/core/contact/contacts'
import {
  GET_CONTACT_ADDON_FIELD_TYPES,
  GET_CONTACT_STATUSES,
  GET_THREAD_TAGS, GET_USER_CONTACTS,
} from '../../@constants/mutations'
import ContactTable from './components/Table.vue'

export default {
  name: 'Clients',
  components: {
    ExportButton,
    BCollapse,
    vSelect,
    flatPickr,
    AssignedUsers,
    ImportContactsModal,
    ContactTable,
    BFormRadioGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    showDelayed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    roles,
    // statuses
    contactStatuses: [],
    additionalFieldsList: [],
    reload: false,
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    searchQuery: '',
    showImport: false,
    // Pagination
    filters: {
      onlyNoOrganizationContacts: false,
      assignedUsers: [],
      status: '',
      structureContacts: false,
      offers: false,
      action: false,
      withOrganization: false,
      agreeMarketing: false,
      timeRange: '',
      tags: [],
      tagsSum: true,
    },
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
    tags: [],
    selectedContact: [],
    pagination: null,

    showMassUpdate: false,
    massUpdateLoading: false,
  }),
  mounted() {
    this.filters.assignedUsers = [this.currentUser]
    if (loadFromLocalStorage('contact_assigned_filter')) {
      this.filters.assignedUsers = loadFromLocalStorage('contact_assigned_filter')
    }

    if (this.showDelayed) {
      this.filters.action = true
    }

    this.loadTypes()
    this.onGetContactTags()
    this.getContacts()
  },
  computed: {
    ...mapGetters({
      total: 'contacts/getTotalRecords',
      currentUser: 'auth/getCurrentUser',
      contacts: 'contacts/getContacts',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
  methods: {
    resolveParams() {
      const params = {
        orderBy: 'createdAt.desc',
      }

      resolveFilters(params, {
        filters: this.filters,
        pagination: null,
        search: '',
        sorter: this.sorter,
        additionalFieldsFilters: this.additionalFieldsList,
      })

      return params
    },
    async deleteContacts(contactId = null) {
      const accepted = await this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('ContactDeleteAlert'))
      if (!accepted) return

      try {
        const payload = JSON.stringify(contactId ? [contactId] : this.selectedContact)
        await axiosIns.delete('1/contacts', { data: payload })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))

        this.reloadContent()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async getProductsToExport() {
      this.exportLoading = true
      try {
        const chunksToLoad = Math.ceil(this.total / this.pagination.perPage)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) =>
        // const payload = {
        //   search: this.search,
        //   filters: this.filters,
        //   attributeFilters: this.attributes,
        //   withFields: this.$fields.PRODUCTS_EXPORT,
        //   pagination: { ...this.pagination, currentPage: index + 1 },
        //   skip: true,
        // }
        // return this.$store.dispatch(`products/${GET_PRODUCTS}`, payload)

        // eslint-disable-next-line implicit-arrow-linebreak
          this.$store.dispatch(`contacts/${GET_USER_CONTACTS}`, {
            filters: this.filters, pagination: { ...this.pagination, currentPage: index + 1 }, search: this.searchQuery, additionalFieldsFilters: this.additionalFieldsList, skip: true,
          })))

        const items = []
        response.forEach(res => {
          items.push(...res.data?.items)
        })

        this.$nextTick(() => {
          this.exportProducts(items)
        })
        // this.$set(this, 'productsToExport', items || [])
        // this.$set(this, 'exportShow', true)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.massPriceUpdateLoading = false
      }
    },
    /* eslint-disable */
    async exportProducts(productList) {
      const wb = XLSX.utils.book_new()
      wb.Props = {
        Title: 'SampleXlsx',
      }

      const order = [
        { key: 'id', label: '#' },
        { key: 'email', label: 'Email', visible: true },
        { key: 'firstName', label: 'Imię', visible: true },
        { key: 'lastName', label: 'Nazwisko', visible: true },
      ]

      const aoaToExport = [
        ...productList.map(t => order.map(({ key }) => {
          if (key === 'name') return this.getTranslationsField(t, 'name').substring(this.getTranslationsField(t, 'name').indexOf(' ')).trim()
          if (key === 'description') return this.getTranslationsField(t, 'description')
          if (key === 'splitName') return this.getTranslationsField(t, 'name').split(' ')[0]
          if (key === 'category') return t.shopCategories.length >= 1 ? this.getTranslationsField(t.shopCategories[0], 'name') : ''
          if (key === 'subCategory') return t.shopCategories.length > 1 ? this.getTranslationsField(t.shopCategories[1], 'name') : ''

          if (key === 'attributes') return this.getProductAttributes(t).flatMap(item => item)

          if (typeof t[key] === 'boolean') return t[key] ? 1 : 0

          return t[key] ?? ''
        }).flat(Infinity)),
      ]
      const longestAoaArrayLength = aoaToExport.reduce((maxLength, currentArray) => {
        const currentLength = currentArray.length;
        return currentLength > maxLength ? currentLength : maxLength;
      }, 0);
      const headers = order.filter(({ key }) => key !== 'attributes').map(({ label }) => this.$i18n.t(label))

      aoaToExport.unshift(headers)

      wb.SheetNames.push('Sample')

      const ws = XLSX.utils.aoa_to_sheet(aoaToExport)
      wb.Sheets.Sample = ws

      XLSX.writeFile(wb, 'Sample.xlsx')

      this.exportLoading = false
    },
    timeConfig(type) {
      return type === 'TIME' ? { enableTime: true, noCalendar: true, dateFormat: 'H:i:ss' } : {}
    },
    async massUpdateState({ field, value }) {
      this.$set(this, field, value)
    },
    reloadContent() {
      if (this.$refs.table) {
        this.$refs.table.getUsers()
      }
    },
    getContacts() {
      this.reload = !this.reload
    },
    filterContacts() {
      this.reload = !this.reload
    },
    clearTimeRange() {
      this.filters.timeRange = ''
      this.reload = !this.reload
    },
    changeAssignedUsers(payload) {
      this.filters.assignedUsers = payload
      saveToLocalStorage('contact_assigned_filter', payload)
      this.reload = !this.reload
    },
    onGetContactTags() {
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.tags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    loadTypes() {
      this.isLoading = true

      this.$store.dispatch(`addonFieldTypes/${GET_CONTACT_ADDON_FIELD_TYPES}`)
        .then(res => {
          this.additionalFieldsList = (res || []).map(attribute => {
            const filter = attribute
            if (['PERCENTAGE', 'TEXT', 'METRIC', 'TIME', 'DATE', 'RADIAL', 'TOOLTIP'].includes(filter.type)) {
              // eslint-disable-next-line
              filter.value = ''
            } else if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(filter.type)) {
              // eslint-disable-next-line
              filter.value = []
            } else {
              filter.value = ['', '']
            }

            return filter
          })
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => {
          this.isLoading = false
        })
    },
    clearFilters() {
      this.additionalFieldsList = this.additionalFieldsList.map(attribute => {
        const filter = attribute
        if (['PERCENTAGE', 'TEXT', 'METRIC', 'TIME', 'DATE'].includes(filter.type)) {
          // eslint-disable-next-line
          filter.value = ''
        } else if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(filter.type)) {
          // eslint-disable-next-line
          filter.value = []
        } else {
          filter.value = ['', '']
        }

        return filter
      })

      this.changeFilter()
    },
    getActiveFilters() {
      const count = this.additionalFieldsList.filter(attribute => {
        if (attribute?.value) {
          if (['PERCENTAGE', 'TEXT', 'METRIC', 'TIME', 'DATE', 'TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(attribute.type)) {
            return attribute.value.length || attribute.value?.id
          }
          if (attribute.type === 'DIGITAL') {
            if (attribute.value.length === 1) return attribute.value[0].length
            if (attribute.value.length === 2) return attribute.value[0].length || attribute.value[1].length
          }
        }

        return false
      }).length
      return count
    },
    changeFilter() {
      this.getContacts()
    },
    loadContactStatuses() {
      this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        .then(res => {
          this.contactStatuses = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
  // setup() {
  //   const {
  //     totalRecords, currentPage, perPage, data, loading,
  //   } = useContacts(true)
  //
  //   return {
  //     totalRecords,
  //     currentPage,
  //     perPage,
  //     loading,
  //     data,
  //   }
  // },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .client_filters {
    label {
      font-size: 1rem;
    }
  }

  .dropdown-menu {
    .active {
      background-color: rgba(115, 103, 240, 0.12);
      color: #7367f0;
    }
  }
</style>
